.accident-info-window {
    padding: 10px;
    width: 100%;
    max-width: 310px;
    font-size: 12px;
    color: #000;

    @media (max-width: 500px) {
        font-size: 11px;
        padding: 8px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        .title {
            font-size: 14px;
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon {
                font-size: 16px;
                margin-inline-end: 6px;
            }
        }

        .link-button.close {
            color: #444;
        }
    }

    > .content {
        display: block;
        max-height: 220px;
        overflow-y: auto;

        .data-list .item {
            align-items: center;
            
            .key {
                min-width: 100px;
                max-width: 100px;
                word-break: break-word;
            }
        }
    }

    .vehicles {
        margin-top: 10px;

        .title {
            margin-bottom: 8px;
        }
    }

    .vehicle-detail {
        margin-bottom: 5px;
        border-radius: 4px;
        padding: 8px;
        background: #8f8f8f;
        color: #fff;

        .title {
            margin: 0;
        }

        .title .text {
            font-size: 12px;
        }

        .toggle-vehicle {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #fff;
            font-weight: bold;
            width: 100%;

            .text {
                margin-inline-start: 5px;
            }
        }

        > .content {
            height: 0;
            overflow-y: hidden;
            transition: 600ms height ease-in-out;
        }
    }

    .vehicle-detail[data-expanded="true"] {
        > .content {
            height: auto;
            margin-top: 10px;
        }
    }

    .vehicle-detail.severity-slight {
        background: #a15d00;
    }

    .vehicle-detail.severity-serious {
        background: #851f1f;
    }

    .vehicle-detail.severity-fatal {
        background: #2b2b2e;
    }

    .casualty-list {
        margin: 0;
        padding: 0;
        list-style: none;

        > .item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 5px;

            .icon {
                font-size: 16px;
            }

            .content {
                margin-inline-start: 8px;
            }
        
            .head-details {
                font-weight: bold;
                display: block;
                margin-bottom: 3px;
            }
        }

        > .item:last-child {
            margin-bottom: 0;
        }
    }

    .vehicle-casualties {
        margin-top: 10px;

        > .title {
            margin-bottom: 5px;
            color: #fff;
        }

        .casualty-list {
            margin-inline-start: 5px;
        }
    }
}



.accident-info-window.severity-slight {
    .header .title .icon {
        color: #ff9601
    }
}

.accident-info-window.severity-serious {
    .header .title .icon {
        color: #ff0000
    }
}

.accident-info-window.severity-fatal {
    .header .title .icon {
        color: #000000
    }
}