.about-page {
    .about-section {
        max-width: 600px;
        margin: 0 auto;
    
        .title {
            font-size: 28px;
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .sub-title {
            font-size: 22px;
            margin-top: 30px;
            margin-bottom: 20px;
        }
    
        .elevator-pitch {
            font-weight: bold;
            font-size: 16px;
        }
    
        .about-links {
            list-style-type: none;
            padding-left: 0;
    
            .item {
                margin-bottom: 5px;
    
                .icon {
                    margin-inline-end: 5px;
                }
    
                .label {
                    margin-inline-end: 5px;
                }
    
                .link {
                    text-decoration: none;
                    font-weight: bold;
                }
            } 
        }

        .sub-section {
            margin-bottom: 30px;
        }

        ol,ul {
            > li {
                margin-bottom: 5px;
                line-height: 1.3em;
            }
        }
    }

    .contact-section {
        text-align: center;

        .contact-list {
            list-style-type: none;
            padding: 0;
            margin: 0;
    
            > .item {
                display: inline-block;
            }
    
            .link {
                display: block;
                color: #444;
                font-weight: bold;
                text-align: center;
                padding: 20px;
                margin: 10px;
                text-decoration: none;
                border: 1px solid #e5ab52;
                border-radius: 5px;
                background: #fff;
                width: 200px;
    
                .icon {
                    font-size: 36px;
                    margin-bottom: 10px;
                }
    
                .value {
                    font-size: 12px;
                    margin: 0
                }
            }

            .link.twitter {
                .icon {
                    color: #1ea1f1;
                }
            }

            .link.instagram {
                .icon {
                    color: #dd316c;
                }
            }

            .link.youtube {
                .icon {
                    color: #fe0000;
                }
            }
        }
    }
}