.app {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 'header' 'main' 'footer';

    // Getting fixed header/footer working on iOS Safari
    @supports not (-webkit-touch-callout: none) {
        height: 100%;
    }

    @supports (-webkit-touch-callout: none) {
        min-height: -webkit-fill-available;
        display: flex;
        flex-direction: column;
    }

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .main-header {
        grid-area: header;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        background: linear-gradient(0deg, #b07823, #b77e29, #be852f, #c48b35, #cb913b, #d29841, #d99f47, #e0a54d, #e7ac53, #eeb259, #f5b95f, #fcc065);
        box-shadow: 0 3px 5px rgba(0,0,0,0.2);
        color: #80581e;
        padding: 5px 20px;
        padding-inline-start: 10px;
        z-index: 3;

        @media (max-width: 700px) {
            padding: 5px 10px;
            padding-inline-start: 0;
        }

        @media (max-width: 600px) {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        @media (max-width: 500px) {
            padding-top: 3px;
            padding-bottom: 3px;
        }

        @media (max-width: 400px) {
            padding-top: 2px;
            padding-bottom: 2px;
        }

        .burger-menu {
            order: 1;
            flex: 0;
            margin-inline-end: 10px;

            .menu-toggle {
                font-size: 24px;
                color: #000;

                .text {
                    color: #7f591d;
                }
            }

            .menu-checkbox:checked + .menu-list {
                width: 250px;
            }
        }

        .titles {
            order: 2;
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .title,
            .sub-title {
                margin: 0;
            }

            .title {
                margin-inline-end: 20px;

                @media (max-width: 500px) {
                    margin-inline-end: 0;
                }

                .logo-link {
                    background: url(./logo.png) left center no-repeat;
                    background-size: contain;
                    font-size: 0;
                    width: 215px;
                    height: 44px;
                    display: block;

                    @media (max-width: 500px) {
                        width: 200px;
                    }

                    @media (max-width: 400px) {
                        width: 180px;
                    }

                    @media (max-width: 370px) {
                        width: 160px;
                    }
                }
            }

            .sub-title {
                font-size: 16px;
                font-weight: bold;
                color: #463216;
                text-shadow: 0 1px #fcbf66, 0 -1px #4e3a1c;
                padding-top: 5px;

                @media (max-width: 1000px) {
                    font-size: 12px;
                }

                @media (max-width: 680px) {
                    display: none;
                }
            }
        }

        .welcome-login {
            order: 3;
            margin: 0;
            font-size: 14px;

            .sign-in,
            .hello-link,
            .logout-button {
                color: #4c3513;
                text-decoration: none;
                font-weight: bold;
                padding: 10px;

                @media (max-width: 800px) {
                    padding: 10px 5px;
                }

                .icon {
                    font-size: 18px;
                    margin-inline-end: 2px;
                }

                .icon,
                .text {
                    vertical-align: middle;
                }
            }

            .sign-in {
                .icon {
                    margin-inline-end: 5px;
                }

                @media (max-width: 520px) {
                    .icon { margin: 0; }
                    .text { display: none; }
                }
            }

            .logged-in {
                display: flex;
                align-items: center;

                .hello-link {
                    margin: 0;
                    
                    @media (max-width: 1000px) {
                        .icon { margin: 0; }
                        .text { display: none; }
                    }
                }

                .logout-button {
                    font-size: 12px;
                    color: #6b4616;

                    @media (max-width: 1200px) {
                        .icon { margin: 0; }
                        .text { display: none; }
                    }

                    @media (max-width: 500px) {
                        display: none;
                    }
                }
            }
        }

        .add-drive {
            order: 4;
            margin-inline-start: 10px;
            box-shadow: 0 3px 5px rgba(0,0,0,0.2);

            @media (max-width: 800px) {
                margin-inline-start: 5px;
            }

            @media (max-width: 1200px) {
                .superfluous {
                    display: none;
                }
            }

            @media (max-width: 500px) {
                font-size: 0;
                padding: 5px;
                height: auto;

                .text { display: none; }
                .icon {
                    font-size: 20px;
                    margin: 0;
                }
            }
        }
    }

    .main-center {
        grid-area: main;
        overflow-y: auto;
        background: #cedae8b9;
        position: relative;

        @supports (-webkit-touch-callout: none) {
            flex: 1;
        }

        > .inner {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }

        .main-page-full {
            overflow-y: auto;
            position: absolute;
            left: 20px;
            top: 20px;
            right: 20px;
            bottom: 20px;
            z-index: 1;
            border-radius: 10px;
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px;
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

            backdrop-filter: blur(20px);
            background: rgba(255, 255, 255, 0.6);

            @supports not(backdrop-filter: blur(20px)) {
                background: rgba(255, 255, 255, 0.95);
            }

            @media (max-width: 600px) {
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                border-radius: 0;
            }

            @media (max-width: 550px) {
                padding: 20px 10px;
            }
        }

        .main-page-half {
            overflow-y: auto;
            position: absolute;
            left: 50%;
            top: 10px;
            right: 10px;
            bottom: 25px;
            z-index: 2;
            padding: 20px;
            padding-top: 10px;
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
            border-radius: 10px;
            transform: translateX(0%);
            transition: 1s transform ease-in-out;

            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(20px);

            @supports not(backdrop-filter: blur(20px)) {
                background: rgba(255, 255, 255, 0.95);
            }

            @media (max-width: 1100px) {
                right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            @media (max-width: 1000px) {
                padding: 15px;
            }

            @media (max-width: 700px) {
                padding: 10px;
                left: 60px;
                top: 40%;
            }
        }

        #main-map {
            position: absolute !important;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;

            .widget-scene-canvas {
                outline: none !important;
            }

            .drive-name-hint-popup {
                text-align: center;
                padding: 5px 8px;
                text-decoration: none;
                color: #444;
                display: block;
                font-size: 11px;
                max-width: 200px;
                font-weight: bold;
            }

            .custom-map-dialog {
                padding: 0 !important;
                max-height: none !important;

                .gm-style-iw-d {
                    overflow: hidden !important;
                    max-height: none !important;
                }

                > button { display: none !important; }
            }

            .gm-style .gm-style-iw-t::after {
                pointer-events: none;
                background: linear-gradient(45deg,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%) transparent;
            }
        }
    }

    .main-footer {
        grid-area: footer;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        background: #FCC065;
        box-shadow: 0 -2px 5px rgba(0,0,0,0.2);
        padding: 8px 20px;
        z-index: 2;

        @media (max-width: 700px) {
            padding: 8px 10px;
        }

        @media (max-width: 600px) {
            padding: 5px 10px;
        }

        .social-links {
            .row-list {
                display: flex;
                align-items: center;
            }

            .row-list > .item {
                margin: 0;
            }

            .link {
                margin-inline-end: 25px;

                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                color: #a30000;
                text-decoration: none;
                font-weight: bold;
                font-size: 12px;

                .icon {
                    color: #444;
                    margin-inline-end: 3px;
                    vertical-align: middle;
                }

                .text {
                    vertical-align: middle;
                }

                @media (max-width: 600px) {
                    margin-inline-end: 15px;

                    .icon { margin: 0; }
                    .text { display: none; }
                }   
            }

            .link.youtube .icon {
                color: #fe0000;
            }

            .link.twitter .icon {
                color: #1ea1f1;
            }

            .link.instagram .icon {
                color: #dd316c;
            }
        }

        .about-links {
            font-size: 12px;

            .row-list > .item {
                margin: 0;
            }

            .link {
                margin-inline-start: 20px;
                text-decoration: none;
                color: #a30000;

                @media (max-width: 400px) {
                    margin-inline-start: 10px;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .app.half-page {
        .main-center > .inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;

            .map-container {
                flex: 1;
                min-height: 50%;
                order: 1;
                position: relative;
                box-shadow: 0 4px 5px rgba(0,0,0,0.2);
                z-index: 2;
            }

            .main-page-half {
                flex: 1;
                min-height: 50%;
                order: 2;
                position: static;
                border-radius: 0;
            }
        }
    }
}

.app.street-view-active {
    .main-center > .inner {
        overflow-y: hidden;

        .main-page-half {
            transform: translateX(110%);

            @media (max-width: 700px) {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 3;
                top: 50%;
                transform: translateY(110%);
            }
        }

        @media (max-width: 700px) {
            .map-container {
                min-height: 100%;
            }
        }
    }
}

.burger-menu {
    position: relative;

    .menu-toggle {
        font-size: 16px;
        cursor: pointer;
        display: block;
        line-height: 1em;
        padding: 10px;
        text-align: center;
        padding-top: 3px;
        padding-bottom: 3px;
        background: none;
        border: none;

        .text {
            font-size: 10px;
            display: block;
            line-height: 1em;
            margin-top: 3px;
        }
    }

    .menu-list {
        display: none;
    }

    .menu-list.visible {
        display: block;
        position: absolute;
        left: 0;
        z-index: 3;
        background: #4c2526;
        color: #fff;
        padding: 0;
        box-shadow: 0 3px 5px rgba(0,0,0,0.4);
        border-radius: 5px;
        width: 250px;

        .list {
            list-style-type: none;
            padding: 0;
            margin: 0;

            .menu-item > .link{
                display: block;
                padding: 10px 15px;
                border-bottom: 1px solid rgba(0,0,0,0.1);
                text-decoration: none;
                color: #fff;
                font-weight: bold;
                width: 100%;
                text-align: start;

                .icon {
                    margin-inline-end: 10px;
                }
            }

            .menu-item > .logout-button {
                color: #ff9700
            }

            .menu-item > .link:hover {
                background: #3c1b1c
            }

            > .menu-item:last-child > .link {
                border-bottom: none;
            }

            .sub-list {
                list-style-type: none;
                padding: 0;
                margin: 0;

                .menu-item > .link {
                    padding: 8px 12px;
                    padding-inline-start: 48px;
                }
            }
        }
    }
}

body.rtl {
    direction: rtl;

    .app {
        .main-center {
            .main-page-half {
                left: 0;
                right: 50%;
            }
        }
    }

    .burger-menu {
        .menu-list.visible {
            left: auto;
            right: 0;
        }
    }

    .rtl-flip {
        transform: scaleX(-1);
    }

    .rtl-flip.icon.fa-rotate-90 {
        transform: scaleX(-1) rotate(90deg);
    }
}