.standard-modal.drive-edit-modal {
    .edit-drive-location-form {
        .label {
            min-width: 90px;
            text-align: end;
        }
    }

    .edit-drive-roads-form {
        .label {
            min-width: 90px;
            text-align: end;
        }

        .control.with-editable-list {
            .controls {
                margin-bottom: 10px;
            }
        }
    }
}