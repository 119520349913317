.register-page {
    .username-row {
        padding-bottom: 20px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;

        .label {
            padding: 5px 0;
            align-self: flex-start;
        }
    }

    .register-success {
        .icon {
            font-size: 76px;
            color: #6bc16e;
            margin-top: 20px;
            margin-bottom: 30px;
        }

        .thank-you {
            font-weight: bold;
        }
    }
}

.activate-page {
    .icon {
        font-size: 76px;
        margin-top: 20px;
        margin-bottom: 30px;
        color: #6bc16e;
    }

    .error .icon {
        color: #ff6767;
    }
}

.forgot-password-page {
    .sent-success {
        .icon {
            font-size: 76px;
            color: #6bc16e;
            margin-top: 20px;
            margin-bottom: 30px;
        }
    }

    .form-aside {
        text-align: start;
    }
}

.reset-password-page {
    .reset-success,
    .error {
        .icon {
            font-size: 76px;
            color: #6bc16e;
            margin-top: 20px;
            margin-bottom: 30px;
        }
    }

    .error .icon {
        color: #ff6767;
    }
}