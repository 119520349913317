.drive-images-modal {
    max-width: 700px;

    @media (max-width: 650px) {
        max-width: 320px;
    }

    .media-form-items {
        margin-bottom: 20px;

        .media-item {
            @media (max-width: 650px) {
                flex-direction: column;
            }

            .media {
                flex: 0;
                min-width: 270px;
                text-align: end;
                display: flex;
                justify-content: center;
                align-items: center;

                .image {
                    max-width: 100%;
                    max-height: 200px;
                }

                .file-upload {
                    width: 100%;

                    .upload-image {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 2px solid #e5ab52;
                        border-radius: 3px;
                        color: #e5ab52;
                        background: #fff3e0;
                        height: 200px;
                        width: 100%;
                        font-size: 24px;

                        @media (max-width: 500px) {
                            height: 35vw;
                        }
                    }
                }
            }

            .details {
                flex: 1;

                @media (max-width: 650px) {
                    padding: 0;
                    margin-top: 20px;
                }

                .form-row {
                    margin-bottom: 10px;

                    textarea {
                        height: 70px;
                        resize: none;
                        font-size: 13px;
                    }

                    .textbox {
                        font-size: 13px;
                    }
                }

                .image-source-form {
                    margin: 0;
                    margin-top: 10px;
                    padding: 0;
                    padding-top: 10px;
                    width: 100%;
                    border: none;
                    border-top: 1px solid #ccc;

                    legend {
                        font-weight: bold;
                        padding: 0;

                        .optional-label {
                            font-weight: normal;
                            font-size: 90%;
                        }
                    }

                    .form-row:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }


    @media (max-width: 650px) {
        .footer-controls {
            .link-button,
            .button {
                font-size: 12px;
            }

            .superfluous {
                display: none;
            }
        }
    }
}