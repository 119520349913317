.place-info-window {
    padding: 10px;
    width: 100%;
    font-size: 12px;
    color: #000;

    @media (max-width: 500px) {
        font-size: 11px;
        padding: 8px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .title {
            font-size: 12px;
            margin: 0;
            display: flex;
            align-items: center;

            .icon {
                margin-inline-end: 8px;
                font-size: 16px;
            }
        }

        .link-button.close {
            color: #444;
            font-size: 14px;
            margin-inline-start: 5px;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    
        .image-container {
            flex: 2;
            flex-basis: 40%;
            min-width: 100px;
            margin-inline-end: 10px;

            @media (max-width: 500px) {
                margin-inline-end: 8px;
            }

            max-height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            .image {
                width: 100%;
            }
        }

        .info {
            flex: 5;
            flex-basis: 60%;
            max-height: 140px;
            overflow-y: auto;
            margin-inline-end: -10px;
            padding-inline-end: 10px;
        }

        .info-rows {
            margin-bottom: 12px;
            word-break: break-word;
        
            .info-row {
                margin-bottom: 5px;
                font-style: normal;
                display: flex;
                align-items: flex-start;

                .icon {
                    margin-inline-end: 8px;
                    font-size: 13px;
                    min-width: 15px;
                }

                .label {
                    margin-inline-end: 8px;
                }

                .link {
                    text-decoration: none;
                    font-weight: bold;
                }

                .text.warn {
                    color: red;
                    font-weight: bold;
                }
            }

            .info-row.status-row {
                margin-bottom: 12px;
            }

            .info-row:last-child {
                margin-bottom: 0;
            }
        }

        .review-info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 12px;

            .star-rating {
                margin-inline-end: 10px;
                .star-icon {
                    font-size: 12px;
                }
            }

            .num-reviews {
                margin: 0;
                line-height: 1em;
                position: relative;
                top: 1px;
            }
        }

        .opening-times {
            margin-bottom: 15px;

            .opening-header {
                display: flex;
                justify-content: flex-start;

                .title {
                    margin: 0;
                    margin-inline-end: 10px;
                }

                .toggle-opening-times{
                    font-size: 11px;
                }
            }

            .open-list {
                margin: 0;
                padding: 0;
                list-style-type: none;
                margin-top: 5px;

                .item {
                    margin-bottom: 3px;
                }

                .item:last-child {
                    margin-bottom: 0;
                }
            }

            .open-list.collapsed {
                display: none;
            }
        }

        .opening-times.closed {
            .opening-header .title {
                color: red;
            }
        }

        .links {
            margin: 0;
            padding: 0;
            list-style-type: none;

            .link-item {
                margin-bottom: 10px;
            }

            .link-item:last-child {
                margin-bottom: 0;
            }
        }

        .maps-url {
            height: 15px;
            background: url(../../images/google-maps-small.png) center left no-repeat;
            background-size: contain;
            padding-left: 17px;
            font-weight: bold;
            text-decoration: none;
            display: inline-block;
            margin-bottom: 0;
        }
    }
}

.place-info-window.not-found {
    .header {
        margin-bottom: 0;
    }
}