@import './common.scss';

body {
    margin: 0;
    font-size: 14px;
    font-family: $body-font;
    color: #444;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: #ecf0f0;

    overflow: hidden;

    @media (max-width: 550px) {
        font-size: 13px;
    }
}

$success-color: #409c43;
$cta-color: #ff3f3f;
$error-color: #ff6767;

@mixin horizontal() {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, ul, ol {
    margin-top: 0;
    
}

h1, h2, h3, h4, h5, h6 {
    font-family: $title-font;
    color: #000;
}

p {
    line-height: 1.5em;
}

a {
    color: #085394;
}

figure {
    margin: 0;
    font-size: 0;

    figcaption {
        font-size: 14px;
    }
}

.button {
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    text-decoration: none;
    font-family: $body-font;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
    margin: 0;
    min-height: 27px;

    background: #e5ab52;
    color: #fff;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
        margin-inline-end: 5px;
    }
}

.button.cta {
    background: $cta-color;
}

.button.chunky {
    font-size: 23px;
    padding-inline-start: 40px;
    padding-inline-end: 40px;
    height: 36px;

    .icon {
        margin-inline-end: 15px;
    }
}

.button.icon-only {
    .icon {
        margin: 0;
    }
}

.button:disabled {
    filter: grayscale(0.7);
}

.icon-button,
.link-button {
    margin: 0;
    padding: 0;
    font-family: $body-font;
    display: inline-block;
    border: none;
    background: none;
    cursor: pointer;
    color: #000;

    .icon {
        margin-inline-end: 5px;
    }
}

.icon-button {
    font-size: 0;
    .icon {
        font-size: 16px;
        margin-inline-end: 0;
    }
}

.link-button.like-button {
    color: $cta-color;
    padding: 1px;
    font-size: 0;

    .icon {
        font-size: 20px;
        margin: 0;
    }
}

.textbox,
textarea,
select {
    border: none;
    border-radius: 3px;
    background: #fff;
    padding: 0 10px;
    font-size: 14px;
    color: #444;
    margin: 0;
    font-family: $body-font;
    box-shadow: inset 0px 1px 1px rgba(0,0,0,0.2);
}

.textbox,
select {
    height: 27px;
}

textarea {
    line-height: 1.3em;
    padding: 5px 10px;
}

.textbox::placeholder,
textarea::placeholder {
    color: #999;
}

.validation-error {
    margin-top: 4px;
    margin-bottom: 0;
    color: $error-color;
    font-size: 13px;

    .icon {
        margin-inline-end: 4px;
        font-size: 90%;
    }
}

form.submitted {
    .textbox:invalid {
        box-shadow: 0 0 1px 1px $error-color;
    }
}

.screen-reader-only {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
}

.file-upload {
    .file {
        opacity: 0;
        width: 0.1px;
        height: 0.1px;
        position: absolute;
    }

    .file:focus + .button {
        outline: 2px auto #77abf0;
        outline: 2px auto Highlight;
        outline: 2px auto -webkit-focus-ring-color;
    }
}

.form {
    border: none;
    background: #eee;
    margin: 0;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);

    @media (max-width: 600px) {
        padding-inline-start: 10px;
        padding-inline-end: 10px;
    }

    legend {
        font-weight: bold;
        font-size: 14px;
        background: #4c2526;
        padding: 3px 10px;
        border-radius: 6px;
        color: #fff;
    }

    legend.with-button {
        background: none;
        padding: 0;
        border-radius: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .text {
            background: #4c2526;
            padding: 4px 10px;
            border-radius: 6px;
            display: block;
            margin: 0;
            margin-inline-end: 20px;
            word-break: break-word;
        }
    }

    .form-row {
        margin-bottom: 10px;
    }

    .form-row.buttons {
        margin-top: 30px;
    }

    .form-row:last-child {
        margin-bottom: 0;
    }
}

.form-row {
    .label {
        display: inline-block;
        margin-bottom: 3px;
        font-weight: bold;
    }

    .textbox,
    textarea,
    select {
        width: 100%;
    }

    textarea {
        resize: vertical;
    }

    .password-control {
        @include horizontal();

        .password-reveal {
            margin: 0;
            padding: 3px 5px;
            width: 40px;
            border-radius: 3px;
            border: none;
            font-size: 17px;
            margin-inline-start: 3px;
            cursor: pointer;
            background: #e5ab52;
            color: #fff;

            .icon {
                vertical-align: text-bottom;
            }
        }
    }

    .optional-label {
        display: inline-block;
        font-weight: normal;
        font-size: 90%;
    }

    .control.with-button {
        display: flex;

        .button {
            margin-inline-start: 5px;
        }
    }

    .control.with-editable-list {
        .controls {
            display: flex;

            .button {
                margin-inline-start: 5px;
            }
        }
    }
}

.form-row.inline {
    .label-control {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {
            margin-inline-end: 10px;
            margin-bottom: 0;
        }

        .control {
            flex: 1;

            .textbox,
            textarea,
            select {
                width: 100%;
            }
        }

        .optional-label {
            margin-inline-start: 10px;
        }
    }
}

.form-row.inline-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .label {
        line-height: 2em;
    }

    .control,
    .read-only-control {
        flex: 1;
        margin-inline-start: 10px;
    }

    .read-only-control {
        min-height: 27px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .read-only-value {
            margin: 0;
            font-style: italic;
            color: #777;
        }
    }
}

.form-row.check-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .checkbox {
        margin: 0;
        margin-top: 3px;
        margin-inline-end: 5px;
    }

    .label {
        margin: 0;
    }
}

.form-row.buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .button,
    .link-button {
        margin-inline-end: 20px;
    }
}

.form-row.buttons.centered {
    justify-content: center;

    .button,
    .link-button {
        margin: 0 10px;
    }
}

.form-row.form-images-upload {
    .label {
        margin-bottom: 10px;
    }

    .control {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: -20px;

        .image-item {
            margin-inline-end: 20px;
            margin-bottom: 20px;

            .button {
                padding: 0;
                width: 100px;
                text-align: center;
                line-height: 100px;

                background: #fff;
                color: #000;
                border: 1px solid #999;
            }

            .image {
                width: 100px;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                > .framed-image {
                    max-width: 100%;
                    max-height: 100%;
                    border-width: 3px;
                }

                .remove-button {
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    color: $cta-color;
                    font-size: 0;

                    .icon {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.form-row.username-row .label-control {
    .label {
        font-weight: normal;
        margin-inline-end: 0;
    }

    .textbox {
        padding-inline-start: 1px;
    }
}

.linked-range-controls {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .range-item {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .remove-controls {
            order: 1;
            margin-inline-end: 10px;
        }

        .main-controls {
            order: 2;
            flex: 1;
        }

        .range-input {
            width: 100%;
        }
    }

    .range-item:last-child {
        margin-bottom: 0;
    }

    .range-control {
        display: flex;

    }
}

.star-rating {
    .star-button {
        border: none;
        padding: 0;
        margin: 0;
        background: none;

        color: #fdbf65;
        font-size: 28px;
        cursor: pointer;
    }

    .star-icon {
        color: #fdbf65;
        font-size: 28px;
    }
}

.star-rating-bw {
    .star-icon {
        color: #444;
    }
}

.image {
    border-radius: 3px;
}

.polaroid,
.app .no-image-icon.polaroid {
    border-radius: 3px;
    border: 10px solid #fff;
    box-shadow: 0 3px 5px rgba(0,0,0,0.2);
    border-bottom-width: 30px;

    @media (max-width: 550px) {
        border-width: 5px;
        border-bottom-width: 15px;
    }
}

.framed-image {
    border-radius: 3px;
    border: 5px solid #fff;
    box-shadow: 0 3px 5px rgba(0,0,0,0.2);
}

.simple-image {
    border-radius: 3px;
    box-shadow: 0 3px 5px rgba(0,0,0,0.2);
}

.app .no-image-icon {
    width: 100%;
    height: 100px;
    border: 5px solid #fff;
    padding: 10px;
    background: #eaeaea;
}

.row-list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    > .item {
        display: inline-block;
        margin-inline-start: 5px;
        margin-inline-end: 5px;
    }

    > .item:first-child {
        margin-inline-start: 0;
    }

    > .item:last-child {
        margin-inline-end: 0;
    }
}

.links-list {
    padding-inline-start: 20px;
    margin: 0;

    > .item {
        margin-bottom: 5px;

        .link {
            text-decoration: none;
            display: inline-block;
            padding: 2px 0;
        }

        .link:hover {
            text-decoration: underline;
        }
    }

    > .item:last-child {
        margin-bottom: 0;
    }
}

.links-list.with-icons {
    padding-inline-start: 0;
    list-style: none;

    .icon {
        margin-inline-end: 8px;
        vertical-align: middle;
        font-size: 18px;
    }

    .link {
        vertical-align: middle;
    }
}

.data-list {
    margin: 0;
    
    .item {
        margin-bottom: 5px;
        display: flex;
        align-items: flex-start;

        .key,
        .value {
            margin: 0;
            line-height: 1.3em;
        }

        .key {
            margin-inline-end: 10px;
            font-weight: bold;
            min-width: 105px;
            text-align: end;
        }
    }

    .item:last-child {
        margin-bottom: 0;
    }

    .item.item-gap-above {
        margin-top: 10px;
    }

    .item.gap-above {
        margin-top: 20px;
    }

    .item.gap-below {
        margin-bottom: 20px;
    }

    .min-max-table {
        border-spacing: 0;

        .key {
            text-align: start;
            font-weight: normal;
            min-width: auto;
            padding: 0;
            padding-bottom: 1px;
            padding-inline-end: 5px;
        }

        .value {
            text-align: end;
            padding: 0;
            padding-bottom: 1px;
            padding-inline-start: 12px;
        }
    }

    .road-types-list {
        list-style-type: none;
        padding-inline-start: 0;
        margin-bottom: 0;
    }
}

.screen-readers-only {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

.elevation-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    @media (max-width: 450px) {
        border-radius: 0;
        border-inline-start: none;
        border-inline-end: none;
    }

    .data-list {
        margin-top: 20px;
        margin-bottom: 0;
        display: flex;
        align-items: flex-start;

        > .item {
            margin-bottom: 0;
        }
    }

    .elevation-graph {
        flex: 1;
        overflow-x: hidden;
        width: 100%;

        .scroller {
            padding-bottom: 10px;
            scroll-snap-type: none;
            scroll-behavior: auto;
        }
    }

    .carousel .scroll-left {
        background: linear-gradient(to left, rgba(255, 255, 255, 0), #dcf7f0);
    }

    .carousel .scroll-right {
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #dcf7f0);
    }

    .carousel .scroll-left,
    .carousel .scroll-right {
        width: 40px;

        .icon {
            color: #009473;
            font-size: 35px;
        }
    }

    .carousel .scroll-right {
        border-radius: 5px;
    }

    @media (max-width: 1300px) {
        flex-direction: column;

        .data-list {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 0;
            margin-inline-end: 5px;
        }

        .gradient-item {
            margin-top: 0;
        }

        .carousel .scroll-left,
        .carousel .scroll-right {
            border-radius: 0;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    @media (max-width: 1200px) {
        .data-list {
            margin-inline-start: 20px;

            .item {
                flex-direction: column;
                margin-inline-end: 40px;

                .key {
                    text-align: start;
                    margin-bottom: 10px;
                }
            }

            .item:last-child {
                margin-inline-end: 0;
            }
        }
    }

    @media (max-width: 900px) {
        .data-list {
            margin-inline-start: 15px;

            .item {
                margin-inline-end: 20px;

                .value {
                    font-size: 12px;
                }
            }
        }
    }

    @media (max-width: 700px) {
        .data-list .item {
            margin-inline-end: 40px;
        }
    }

    @media (max-width: 380px) {
        .data-list {
            .item {
                margin-inline-end: 20px;

                .value {
                    font-size: 11px;
                    
                }
            }

            .min-max-table .value {
                padding-inline-start: 5px;
            }
        }
    }
}

.tab-control .tab-content.elevation-details {
    padding: 0;
}

.tab-control {
    margin-top: 20px;
    position: relative;

    @media (max-width: 900px) {
        margin-inline-start: -15px;
        margin-inline-end: -15px;
    }

    @media (max-width: 700px) {
        margin-inline-start: -10px;
        margin-inline-end: -10px;
    }

    > .tab-list {
        @media (max-width: 900px) {
            margin-inline-start: 10px;
        }

        .tab-button {
            font-size: 13px;
            padding: 5px 20px;
            margin: 0;
            background: #eee;
            border: 1px solid #90c5b9;
            border-start-start-radius: 5px;
            border-start-end-radius: 5px;
            border-bottom: none;
            margin-inline-end: 2px;
            position: relative;
            cursor: pointer;
            font-family: $body-font;
            color: #000;

            @media (max-width: 900px) {
                padding-inline-start: 15px;
                padding-inline-end: 15px;
                font-size: 12px;
            }
        }

        .tab-button:last-child {
            margin-inline-end: 0;
        }

        .tab-button.selected {
            background: #fff;
        }

        .tab-button.selected::after {
            content: " ";
            position: absolute;
            left: 0;
            bottom: -1px;
            right: 0;
            height: 1px;
            background: #fff;
        }
    }

    .tab-content {
        border: 1px solid #90c5b9;
        border-radius: 5px;
        background: #fff;
        border-start-start-radius: 0;
        padding: 20px;

        @media (max-width: 900px) {
            padding: 15px;

            border-radius: 0;
            border-inline-start: none;
            border-inline-end: none;
        }

        @media (max-width: 700px) {
            padding-inline-start: 10px;
            padding-inline-end: 10px;
        }

        .tab-title {
            font-size: 14px;
            padding: 0;
            margin: 0;
            background: #eee;
            border-radius: 5px;
            position: absolute;
            background: #fff;
            left: 1px;
            top: 1px;
            font-weight: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;

            @media (max-width: 900px) {
                font-size: 12px;
            }
        }
    }

    .tab-content.tab-hidden {
        display: none;
    }
}



.breadcrumbs {
    margin-bottom: 15px;

    .list {
        padding: 0;
        margin: 0;
        list-style-type: none;

        .item {
            display: inline-block;
        }

        .item::after {
            content: ">";
            display: inline-block;
            margin: 0 5px;
        }

        .item:last-child .link {
            color: #444;
            text-decoration: none;
        }

        .item:last-child::after {
            display: none;
        }
    }
}

.split-page-content {
    padding-top: 0;

    .title-container .title {
        font-size: 38px;
        margin-bottom: 5px;

        @media (max-width: 1500px) {
            font-size: 32px;
        }

        @media (max-width: 1100px) {
            font-size: 28px;
        }

        @media (max-width: 700px) {
            font-size: 24px;
        }
    }

    .form-row.buttons {
        text-align: start;
    }

    .content-image-header.has-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
        margin-inline-start: -20px;
        margin-inline-end: -20px;
        padding: 20px;
        padding-bottom: 0;
        margin-bottom: 30px;
        padding-top: 0;
        color: #fff;
        box-shadow: 0 3px 3px rgba(0,0,0,0.3);

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        min-height: 400px;

        @media (max-width: 700px) {
            margin-inline-start: -10px;
            margin-inline-end: -10px;
            padding-inline-start: 10px;
            padding-inline-end: 10px;

            min-height: 37vh;
        }
    }
}

.search-map {
    margin: 0;
    padding: 0;
    border: none;
    background: #eee;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 3px 5px rgba(0,0,0,0.2);

    @include horizontal();

    .use-location {
        @include horizontal();

        .button {
            width: 100%;
        }
    }

    .or {
        margin: 0;
        margin-inline-start: 20px;
        margin-inline-end: 20px;
        text-align: center;
    }

    .use-address {
        @include horizontal();
        flex: 1;

        .form-row {
            flex: 1;
            margin-inline-end: 10px;
        }
    }

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: stretch;

        .use-location { padding-bottom: 4px; }
        .use-address { margin-top: 5px; }
    }

    @media (max-width: 450px) {
        .or { display: none }

        .use-location {
            padding-bottom: 7px;
            border-bottom: 1px solid #ddd;
        }
        .use-address { margin-top: 7px; }
    }

    @media (max-width: 370px) {
        .search-location-buton {
            .icon { margin: 0; }
            .text { display: none; }
        }
    }

    legend {
        display: none;
    }
}

.home-page {
    position: absolute;
    top: 20px;
    z-index: 2;
    margin: 0 auto;
    max-width: 600px;
    left: 10px;
    right: 10px;

    transform: translateY(0);
    transition: transform 1s ease-in-out;

    @media (max-width: 600px) {
        max-width: 420px;
        top: 10px;
    }
}

.app.street-view-active {
    .home-page {
        transform: translateY(-100px);
    }
}

.address-information {
    margin-bottom: 20px;
    font-style: normal;

    .from-to {
        margin-bottom: 2px;
        display: block;

        .from,
        .to {
            display: inline-block;
        }

        .icon {
            margin-inline-start: 10px;
            margin-inline-end: 10px;
        }
    }

    .location {
        font-size: 24px;
        margin: 0;
        display: block;
        line-height: 1.3em;
    }
}

.narrow-form-page {
    padding: 20px 50px;

    .header {
        text-align: center;
        max-width: 500px;
        margin: 0 auto;

        .title {
            margin-bottom: 10px;
            font-size: clamp(24px, 4.5vw, 38px);
        }

        margin-top: 40px;
        margin-bottom: 50px;
    }

    > .inner {
        text-align: center;
        max-width: 400px;
        margin: 0 auto;

        .form {
            .form-row {
                text-align: start;
            }
        }
    }

    .form-aside {
        margin-top: 30px;

        .or {
            display: block;
            margin: 20px 0;
        }

        p {
            margin-bottom: 0;
        }
    }
}

.narrow-form-section {
    text-align: center;
    max-width: 900px;
    margin: 0 auto;

    .header {
        text-align: center;
        max-width: 500px;
        margin: 0 auto;

        .title {
            margin-bottom: 30px;
            font-size: clamp(24px, 4.5vw, 38px);
        }

        margin-top: 40px;
        margin-bottom: 50px;
    }
}

.image-modal {
    width: 100%;
    max-width: 90%;
    height: 100%;
    max-height: 90%;
    border-radius: 8px;
    background: #111;
    box-shadow: 0 10px 15px rgba(0,0,0,0.4);
    padding: 10px 10px 0 10px;
    position: relative;
    word-break: break-word;

    @media (max-width: 450px) {
        max-width: 95%;
        max-height: 95%;
    }

    .image-and-caption {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .image-container {
            position: relative;
            flex: 1;
            align-self: normal;

            .image {
                max-width: 100%;
                max-height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }

        .caption {
            font-size: 16px;
            padding: 15px;
            color: #fff;
            margin: 0 auto;
            word-break: break-word;

            @media (max-width: 450px) {
                font-size: 13px;
                padding-inline-start: 0;
                padding-inline-end: 0;
            }

            .author,
            .uploader {
                margin-inline-start: 10px;

                .link {
                    color: #FCC065;
                }
            }
        }
    }

    .close-button {
        position: absolute;
        color: red;
        top: 0;
        right: 0;
        font-size: 0px;
        background: rgba(0,0,0,0.8);
        padding: 10px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 50%;
        z-index: 2;

        .icon {
            font-size: 20px;
        }
    }
}

.error-modal {
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 10px 15px rgba(0,0,0,0.4);
    padding: 20px;
    position: relative;

    .logo {
        width: 100%;
        max-width: 275px;
        margin-bottom: 30px;
        margin-inline-start: -8px;
    }

    .title {
        font-size: 20px;
        margin: 0;
        margin-bottom: 20px;
        color: $error-color;
    }

    .message {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .error-id {
        font-size: 14px;
        color: #777;
        margin-bottom: 20px;
    }

    .footer-links {
        .contact-link {
            font-size: 13px;
            text-decoration: none;
            font-weight: bold;
            margin-right: 10px;
        }
    }
}

.error-page {
    max-width: 600px;
    margin: 100px auto;
    margin-bottom: 50px;
    padding: 20px;

    .logo {
        width: 100%;
        max-width: 400px;
        margin-bottom: 30px;
        margin-inline-start: -8px;
    }

    .title {
        font-size: 24px;
        margin: 0;
        margin-bottom: 30px;
        color: $error-color;
    }

    .message {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .error-id {
        font-size: 14px;
        color: #777;
        margin-bottom: 20px;
    }

    .footer-links {
        .contact-link {
            font-size: 13px;
            text-decoration: none;
            font-weight: bold;
            margin-right: 10px;
        }
    }
}

.not-found-content {
    > .title {
        font-size: clamp(22px, 2vw, 32px);
        margin-bottom: 20px;

        .icon {
            margin-inline-end: 10px;
        }
    }

    p {
        max-width: 600px;
    }

    .decor-icon {
        font-size: 48px;
    }
}

.main-page-full .not-found-content,
.profile-page .not-found-content {
    max-width: 600px;
    margin: 30px auto;

    p { max-width: auto; }
}

.loading-detail-page {
    margin-top: 80px;
    margin-bottom: 30px;

    .loading-message {
        text-align: center;
        color: #ccc;

        .icon {
            font-size: 38px;
        }
    }
}

.inline-range-control {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
        margin-inline-end: 5px;
        font-weight: bold;
    }

    .range-input {
        width: 100%;
    }

    .value {
        margin-inline-start: 5px;
    }
}

.inline-range-controls {
    .inline-range-control {
        margin-bottom: 5px;
    }

    .inline-range-control:last-child {
        margin-bottom: 0;
    }
}

.fake-fieldset {
    border: 1px solid #ddd;
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    position: relative;
    margin-top: 10px;

    .title {
        position: absolute;
        display: inline-block;
        font-size: 14px;
        margin: 0;
        left: 15px;
        top: -0.7em;
        padding: 0 5px;
        background: #fff;
        font-weight: normal;
    }

    .fieldset-help {
        position: absolute;
        display: inline-block;
        right: 15px;
        top: -0.7em;
        font-size: 12px;
        padding: 0 5px;
        background: #fff;
        color: #ff3f3f;
    }
}

.dialog-modal {
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.9);
    border: none;
    padding: 0;

    > .inner {
        background: #ebebeb;
        width: 100%;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        
        > .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
    
            background: #fcbe64;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
    
            @media (max-width: 450px) {
                padding: 10px;
            }
    
            .title {
                margin: 0;
                color: #a06000;
                text-shadow: 0 -0.5px #3c2400, 0 0.5px #ffd799;
                margin-inline-end: 15px;
    
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                .text {
                    margin-inline-start: 15px;
                    font-size: clamp(12px, 1vw, 16px);
                    word-break: break-word;
                }
            }
    
            .close {
                flex: 0;
            }
    
            .icon {
                font-size: 24px;
                margin: 0;
            }
        }
    
        > .main {
            padding: 15px;
            overflow-y: auto;
    
            @media (max-width: 450px) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    
        > .footer {
            display: flex;
            justify-content: space-between;
            padding: 20px;
            border-top: 1px solid #ddd;
    
            .button {
                margin-inline-end: 20px;
            }
    
            .button:last-of-type {
                margin-inline-end: 0;
            }
    
            @media (max-width: 450px) {
                .button,
                .link-button {
                    font-size: 12px;
                    margin-inline-end: 10px;
                }
    
                .superfluous {
                    display: none;
                }
            }
    
            .primary-button {
                margin-inline-start: 0;
            }
    
            .flex-gap {
                flex: 1;
            }
    
            .delete-button {
                float: inline-end;
                margin-inline-end: 0;
                background: red;
            }
        }
    }
}

.dialog-modal::backdrop {
    background: rgba(0,0,0,0.8);
}

.csv-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .item {
        display: inline-block;
        margin-inline-end: 5px;
        margin-bottom: 0.2em;
    }

    .item::after {
        content: ", ";
    }

    .item:last-child::after {
        content: "";
    }
}

.scrolling-hint {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    transition: 500ms opacity ease-in-out;
    z-index: 2;

    .icon-hint-container {
        padding-top: 5px;
        border: 5px solid #000;
        border-radius: 15px;
        height: 60px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0 0 5px rgb(255, 255, 255, 0.7);
        
        .icon {
            color: #000;
            position: relative;
            top: 0;
            animation: scrollingHintArrow 1s ease-in-out;
            animation-iteration-count: infinite;
        }
    }
}

@keyframes scrollingHintArrow {
    0% {
        top: 0;
    }

    90% {
        top: 30px
    }

    100% {
        top: 0;
    }
}