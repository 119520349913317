.add-drive-page {
    .drive-upload-instructions {
        text-align: start;
        margin-top: 20px;

        > li {
            margin-bottom: 5px;
        }
    }

    .form .form-row.buttons {
        margin-top: 20px;
    }

    .report-invalid-url-message {
        margin-top: 5px;
    }
}