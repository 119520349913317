.drive-detail-page {
    .drive-detail-article {
        .title-container {
            margin-bottom: 15px;

            .title {
                margin-bottom: 0;
                color: #000;
                word-break: break-word;
            }

            .title-sub-info {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .edit-drive-button {
                    min-height: auto;
                    font-size: 12px;
                    margin-inline-start: 10px;
                    line-height: 1.3em;
                    color: #ff403f;
                }
            }

            .drive-author {
                font-size: 12px;
                color: #777;
                margin: 0;

                @media (max-width: 900px) {
                    font-size: 10px;
                }

                a {
                    color: #444;
                    text-decoration: none;
                    word-break: break-word;
                }
            }
        }

        .drive-hero-image {
            width: 100%;
            padding: 0;
            font-size: 0;
            height: 400px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: -1;

            .image {
                height: 400px;
                object-fit: cover;
                width: 100%;
            }

            @media (max-width: 700px) {
                height: 37vh;

                .image {
                    height: 37vh;
                }
            }
        }

        .drive-actions {
            margin-bottom: 20px;
        }

        .drive-meta-data {
            margin-bottom: 30px;
        }

        .drive-info-tabs {
            margin-bottom: 50px;
        }

        .content-image-header.has-image {
            position: relative;
            margin-bottom: 20px;

            @media (max-width: 700px) {
                height: 37vh;
            }

            .title-container {
                margin-inline-start: -20px;
                margin-inline-end: -20px;
                padding: 10px 20px;
                background: rgba(0,0,0,0.6);
                backdrop-filter: blur(10px);
                display: block;

                @media (max-width: 700px) {
                    margin-inline-start: -10px;
                    margin-inline-end: -10px;
                    padding: 10px 10px;
                }

                .title {
                    color: #fff;
                }

                .drive-author {
                    color: #ccc;
                    margin-bottom: 0;

                    a {
                        color: #ccc;
                        font-weight: bold;
                    }
                }

                .title-sub-info .edit-drive-button {
                    color: #fff;
                }
            }

            .drive-actions {
                border-bottom: none;
                background: rgba(0,0,0,0.6);
                align-items: center;
                backdrop-filter: blur(20px);
                margin-inline-start: -20px;
                margin-inline-end: -20px;
                padding: 8px 20px;
                margin-bottom: 0;

                @media (max-width: 700px) {
                    margin-inline-start: -10px;
                    margin-inline-end: -10px;
                    padding: 5px 10px;
                }

                .drive-review {
                    .review-summary {
                        color: #fff;
                    }

                    .add-review-button {
                        font-weight: bold;
                    }
                }

                .send-to-google {
                    .link-button {
                        color: #fff;

                        .text {
                            font-weight: bold;
                        }
                    }

                    .link-button.maps-view-link {
                        font-size: 14px;
                    }
                }

                .drive-likes .like-summary {
                    color: #fff;
                }
            }
        }

        .drive-description {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #cce5df;

            .title {
                margin: 0;
                display: inline-block;
                font-size: 18px;
                margin-inline-end: 5px;
            }

            .summary-text {
                margin: 0;
                display: inline;
            }
        }
    }

    .drive-detail-article.has-image {
        margin-top: -10px;

        @media (max-width: 1000px) {
            margin-top: -15px;
        }

        @media (max-width: 700px) {
            margin-top: -10px;
        }
    }
}

.drive-actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    padding-bottom: 5px;
    border-bottom: 1px solid #dedede;
    
    .drive-review {
        .aggregate-review-text {
            margin: 0;
        }

        .star-rating {
            margin-top: 2px;
            margin-bottom: 3px;
            display: block;

            .star-icon {
                font-size: 24px;

                @media (max-width: 900px) {
                    font-size: 18px;
                }
            }
        }

        .review-summary,
        .add-review-button {
            font-size: 12px;
            color: #777;
            margin: 0;
            display: block;

            @media (max-width: 900px) {
                font-size: 10px;
            }
        }

        .add-review-button {
            color: #ff403f;

            .icon {
                margin-inline-end: 5px;
                position: relative;
                top: 1px;
            }
        }
    }

    .drive-review.no-rating {
        .star-rating .star-icon {
            color: #ccc;
        }
    }

    .send-to-google {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .google-maps-icon {
            width: 24px;
            height: 24px;
            margin-inline-end: 5px;
            background: url(../../images/google-maps-small.png) center center no-repeat;
            background-size: contain;
            font-size: 0;
        }

        .map-links {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        .link-button {
            text-decoration: none;
            color: #777;;
            font-size: 12px;
            padding: 2px 2px;
            display: inline-block;

            @media (max-width: 900px) {
                font-size: 10px;
            }

            .text {
                color: #ff403f;
            }
        }

        .link-button:hover {
            .text {
                text-decoration: underline;
            }
        }
    }

    .drive-likes {
        .like-button {
            .icon {
                font-size: 24px;
                margin: 0;
            }
        }

        .like-summary {
            font-size: 12px;
            color: #777;
            margin: 0;
            text-align: center;
        }
    }
}

.drive-info-not-present {
    margin-bottom: 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;

    .link-button {
        color: #ff403f;
        font-size: 16px;

        .icon {
            margin-inline-end: 8px;
        }
    }
}

.drive-info-section {
    margin-bottom: 50px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;

        .title {
            font-size: 22px;
            margin: 0;

            @media (max-width: 900px) {
                font-size: 18px;
            }

            .icon-container {
                display: inline-block;
                width: 35px;
            
                .icon {
                    font-size: 22px;
                }
            }
        }

        .controls {
            .link-button {
                color: #ff403f;

                @media (max-width: 900px) {
                    font-size: 12px;
                }
            }

            @media (max-width: 480px) {
                .superfluous {
                    display: none;
                }
            }
        }
    }

    .drive-info-main {
        margin-left: 35px;

        @media (max-width: 1000px) {
            margin-left: 0;
        }

        .expand-records {
            margin-top: 30px;

            .link-button {
                color: #ff403f;
            }
        }

        .loading-message {
            color: #777;
            display: flex;

            .icon {
                font-size: 20px;
            }

            .text {
                margin-inline-start: 10px;
            }
        }
    }
}

.drive-reviews {
    .review-list {
        .drive-reviews-list {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 0 -20px;
            flex-wrap: wrap;

            .drive-review {
                padding: 0 20px;
                width: 50%;
                margin-bottom: 40px;
            }
            
            .drive-review:nth-last-child(2),
            .drive-review:last-child {
                margin-bottom: 0;
            }

            @media (max-width: 1100px) {
                margin: 0;

                .drive-review,
                .drive-review:nth-last-child(2) {
                    padding: 0;
                    width: 100%;
                    padding-bottom: 20px;
                    border-bottom: 1px dashed #ddd;
                    margin-bottom: 20px;
                }

                .drive-review:last-child {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;
                }
            }
        }

        .drive-reviews-list.just-one .drive-review {
            width: 100%;
        }
    }
}

.drive-review {
    .review-text {
        margin-bottom: 20px;
        word-break: break-word;

        p { margin-bottom: 0; }

        .read-more {
            margin-top: 3px;
            display: inline-block;
            font-weight: bold;
            text-decoration: none;
            color: #ff403f;

            .text {
                margin-inline-start: 5px;
            }
        }
    }

    .review-info {
        display: flex;
        justify-content: flex-start;

        .review-avatar {
            width: 50px;
            height: 50px;
            margin-inline-end: 10px;
            border-radius: 3px;
            box-shadow: 0 2px 5px rgba(0,0,0,0.5);
        }

        .review-avatar.no-image {
            padding: 5px;
            background: #eaeaea;
            border: 2px solid #fff;
        }

        .star-rating-link {
            color: #444;
        }
    
        .star-rating-bw {
            font-size: 14px;
            margin-bottom: 10px;
        }
    
        .author {
            margin-bottom: 0;
            font-size: 12px;
            font-weight: bold;

            a {
                word-break: break-word;
            }
        }
    }
}

.youTube-carousel {
    .author {
        margin-bottom: 0;
    }

    .yt-video {
        @media (max-width: 440px) {
            width: 300px;
        }

        @media (max-width: 380px) {
            width: 280px;
            height: 180px;
        }

        @media (max-width: 360px) {
            width: 260px;
            height: 160px;
        }

        @media (max-width: 340px) {
            width: 240px;
            height: 150px;
        }
    }
}

.drive-photos {
    .photo-grid {
        display: grid;
        grid-template-columns: 32% 32% 32%;
        column-gap: 2%;
        row-gap: 1vw;

        @media (max-width: 1300px) {
            grid-template-columns: 49% 49%;
            column-gap: 2%;
        }

        @media (max-width: 850px) {
            grid-template-columns: 100%;
            column-gap: 0;
            row-gap: 20px;
        }

        @media (max-width: 700px) {
            grid-template-columns: 49% 49%;
            column-gap: 2%;
            row-gap: 2vw;
        }

        @media (max-width: 500px) {
            grid-template-columns: 100%;
            column-gap: 0;
            row-gap: 20px;
        }

        .photo-item {
            height: 240px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            background: #fff;
            border-radius: 3px;
            box-shadow: 0 3px 5px rgba(0,0,0,0.2);
            position: relative;

            .photo {
                width: 100%;
                height: 100%;
                flex: 1;
                position: relative;

                .image {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
            }

            .author {
                margin-top: 5px;
                font-size: 12px;
                width: 100%;
                text-align: center;

                .caption {
                    word-break: break-word;
                    display: block;
                    margin-bottom: 3px;
                }

                .author-by {
                    word-break: break-word;
                    display: block;
                }
            }

            .image-controls {
                position: absolute;
                right: 5px;
                top: 2px;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                .edit-image-button,
                .hero-image-button {
                    color: #ff403f;
                    font-size: 0;
                    padding: 3px;
                    background: rgba(255,255,255,0.5);
                    border-radius: 3px;
                    
                    .icon {
                        font-size: 13px;
                    }
                }

                .hero-image-button {
                    color: #2d79da;
                    margin-inline-end: 3px;

                    .icon { font-size: 16px; }
                }
            }
        }
    }
}

.carousel {
    position: relative;

    .scroller {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;

        > .carousel-item {
            margin-inline-end: 10px;
        }

        > .carousel-item:last-child {
            margin-inline-end: 0;
        }
    }
}

.carousel .scroll-left,
.carousel .scroll-right {
    display: block;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 80px;
    cursor: pointer;
    z-index: 2;

    .icon {
        color: #ff403f;
        font-size: 60px;
        margin-bottom: 20px;
    }
}

.carousel .scroll-left {
    left: 0;
    background: linear-gradient(90deg, #e4eeee, transparent);
}

.carousel .scroll-right {
    right: 0;
    background: linear-gradient(90deg, transparent, #e4eeee);
}

.toggle-type-button {
    box-shadow: 0 2px 2px rgba(0,0,0,0.8);
    min-width: 130px;
}

.toggle-type-button.selected {
    box-shadow: inset 0 2px 2px rgba(0,0,0,0.6);
    position: relative;
    top: 2px;

    .icon,
    .text {
        filter: drop-shadow(0 0 4px #fff);
    }
}

.place-types-control {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .toggle-type-button {
        margin-inline-end: 10px;
        margin-bottom: 10px;
    }

    .toggle-type-button:last-child {
        margin-inline-end: 0;
    }
}

.content-image-header .place-types-control {
    position: absolute;
    left: 0;
    top: 75px;
    z-index: 1;
    padding: 10px;
}

body.rtl {
    .carousel .scroll-left {
        left: auto;
        right: 0;
        background: linear-gradient(90deg, transparent, #e4eeee);
    }

    .carousel .scroll-right {
        left: 0;
        right: auto;
        background: linear-gradient(90deg, #e4eeee, transparent);
    }
}

.map-key {
    margin: 0;
    padding: 0;
    list-style-type: none;

    > .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;

        .link-button {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: start;
            color: #000;

            .name {
                cursor: pointer;
            }
        }
    }

    > .item:last-child {
        margin-bottom: 0;
    }

    > .item.hidden {
        opacity: 0.5;
    }

    .key {
        width: 15px;
        margin-inline-end: 10px;
    }

    .name {
        font-weight: bold;
    }
}

.drive-accident-controls {
    .no-accidents-message {
        margin: 0;
        display: flex;
        align-items: center;

        .icon {
            margin-inline-end: 5px;
        }
    }

    .safety-record {
        margin-bottom: 30px;

        .data-list {
            .item .value {
                display: flex;
                align-items: center;
            }

            .average {
                font-size: 12px;
                color: #777;
                margin-left: 10px;
            }

            .enlarge {
                font-size: 24px;
            }
        }

        .risk-factors {
            margin-top: 30px;

            .data-list {
                .item .key {
                    min-width: 120px;
                }
            }
        }
    }

    .accident-controls-header {
        display: flex;
        align-items: center;

        .button {
            min-width: 200px;
        }

        .button.toggle-accidents-button {
            margin-inline-end: 10px;
        }

        @media (max-width: 1023px) {
            justify-content: space-between;

            .button {
                flex: 1;
                max-width: 49%;
                min-width: auto;
                font-size: 13px;
            }

            .button.toggle-accidents-button {
                margin-inline-end: 1%;
            }

            .button.toggle-heat-map-button {
                margin-inline-start: 1%;
            }
        }

        .admin-controls {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-inline-start: auto;
    
            .button {
                margin-inline-end: 10px;
                padding: 0 10px;
                min-height: 20px;
                font-size: 12px;
                min-width: auto;
            }
    
            .button:last-child {
                margin-inline-end: 0;
            }
        }
    }

    .accident-controls {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-top: 20px;
        flex-wrap: wrap;

        .title {
            font-size: 13px;
        }

        .map-key .item  .name {
            min-width: 80px;
            font-size: 12px;
        }

        $padding: 20px;
        $reducedPadding: 10px;

        .control {
            border-inline-end: 1px solid #ddd;

            padding-inline-start: $padding;
            padding-inline-end: $padding;

            @media (max-width: 1100px) {
                padding-inline-start: $reducedPadding;
                padding-inline-end: $reducedPadding;
            }
        }

        .control:first-child {
            padding-inline-start: 0;
        }

        .control:last-child {
            padding-inline-end: 0;
        }

        .control:last-child {
            margin-inline-end: 0;
            border-inline-end: none;
            padding-inline-end: 0;
        }

        .breaker {
            flex-basis: 100%;
            height: 0;
        }

        .weather-conditions-control-breaker {
            display: none;

            @media (max-width: 920px) {
                display: block;
            }
        }

        .severity-control {
            min-width: 130px;
            margin-bottom: 20px;
        }

        .years-control {
            flex: 1;
            margin-bottom: 20px;

            @media (max-width: 1280px) {
                padding-inline-end: 0;
                border-inline-end: none;
            }
            
            .inline-range-control .label {
                min-width: 32px;
                font-size: 12px;
            }
        }

        .years-control-breaker {
            display: none;
            @media (max-width: 1280px) {
                display: block;
            }
        }

        .vehicle-type-control {
            min-width: 170px;
            border-right: none;
            padding-inline-end: 0;
            margin-bottom: 20px;

            @media (max-width: 1280px) {
                padding-inline-start: 0;
                padding-inline-end: $padding;
                border-inline-end: 1px solid #ddd;
                min-width: 130px;
                margin-bottom: 0;

                .title {
                    font-size: 12px;
                }

                .map-key > .item .name {
                    font-size: 10px;
                }

                .superfluous {
                    display: none;
                }
            }

            @media (max-width: 1100px) {
                padding-inline-end: $reducedPadding;
            }

            .map-key > .item {
                margin-bottom: 10px;

                .icon {
                    font-size: 16px;
                    margin-inline-end: 5px;
                }
            }

            .map-key > .item:last-child {
                margin-bottom: 0;
            }
        }

        @media (max-width: 1280px) {
            .vehicle-type-control-breaker {
                display: none;
            }
        }

        .accident-conditions {
            flex: 1;

            .title {
                font-size: 12px;
                margin-bottom: 10px;

                .toggle-all {
                    color: #ff403f;
                    font-size: 11px;
                }

                @media (max-width: 1280px) {
                    .superfluous {
                        display: none;
                    }
                }
            }
        }

        .accident-condition-light {
            padding-inline-start: 0;
            max-width: 130px;

            @media (max-width: 1280px) {
                padding-inline-start: $padding;
            }

            @media (max-width: 1100px) {
                padding-inline-start: $reducedPadding;
            }
        }

        .accident-conditions-list {
            margin: 0;
            padding: 0;
            list-style-type: none;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            > .item {
                .toggle {
                    text-align: center;
                    margin: 0 3px;
                    min-width: 28px;

                    .label {
                        margin-top: 5px;
                        font-size: 11px;
                        font-weight: bold;
                        display: block;

                        .count {
                            font-size: 10px;
                            display: block;
                            font-weight: normal;
                        }
                    }
                }
            }

            > .item:first-child .toggle {
                margin-inline-start: 0;
            }

            > .item:last-child .toggle {
                margin-inline-end: 0;
            }

            > .item.hidden .toggle {
                opacity: 0.3;
            }

            @media (max-width: 1280px) {
                display: block;

                > .item .toggle {
                    text-align: start;
                    margin: 10px 0;

                    display: flex;
                    align-items: center;

                    .label {
                        margin-top: 0;

                        .count {
                            display: inline;
                        }
                    }

                    .icon {
                        margin-inline-end: 5px;
                    }

                    br {
                        display: none;
                    }
                }
            }
        }

        @media (max-width: 920px) {
            .accident-condition-weather {
                padding-inline-end: 0;
                border-inline-end: none;
            }

            .accident-condition-road {
                margin-top: 20px;
                padding-inline-start: 0;

                .title .superfluous {
                    display: inline;
                }

                .accident-conditions-list {
                    display: flex;
                    flex-direction: row;

                    > .item .toggle {
                        flex-direction: column;
                        width: 50px;
                        text-align: center;
                        margin: 0;

                        .icon {
                            margin: 0;
                            margin-bottom: 5px;
                        }

                        .count {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.traffic-info {
    > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .tab-list {
        .tab-item {
            font-size: 13px;
            margin: 0;
            padding-inline-start: 5px;
            border-inline-end: 1px solid #ddd;
            padding-inline-end: 5px;
            font-weight: bold;
            color: #777;
        }

        .tab-item.selected {
            text-decoration: underline;
            color: #000;
        }

        .tab-item:first-child {
            padding-inline-start: 0;
        }

        .tab-item:last-child {
            border-inline-end: 0;
            border-inline-end: none;
        }
    }

    .traffic-info-section.tab-hidden,
    .traffic-info-sub-section.tab-hidden {
        display: none;
    }

    .footer {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .admin-traffic-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-inline-start: auto;

        .button {
            margin-inline-end: 10px;
            padding: 0 10px;
            min-height: 20px;
            font-size: 12px;
        }

        .button:last-child {
            margin-inline-end: 0;
        }
    }

    .traffic-data-table.traffic-summary-table {
        width: 100%;
    }

    .traffic-data-table.daily-traffic-table {
        width: 100%;
        min-width: 550px;
    }

    .traffic-data-table.weekly-traffic-table {
        width: 100%;
        min-width: 500px;
    }

    .traffic-data-table.monthly-traffic-table {
        width: 100%;

        .day-col {
            width: 65px;
        }

        .day-col.empty-col {
            width: 40px;
        }
    }

    .traffic-summary {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .scrollable-table {
        overflow-x: auto;
        padding-bottom: 10px;
        margin-bottom: -5px;
    }

    .table-chart-tabs {
        .tab-item {
            margin-inline-end: 10px;
            padding-inline-end: 10px;
            padding-inline-start: 0;

            .icon {
                margin-inline-end: 8px;
            }
        }

        .tab-item:last-child {
            margin-inline-end: 0;
            padding-inline-end: 0;
        }
    }
}

.traffic-info.no-traffic-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .message {
        margin: 0;
    }
}

.traffic-data-table {
    border-spacing: 0;
    font-size: 13px;
    border: 1px solid #c8e2dc;
    table-layout: fixed;

    th,td {
        border-inline-end: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 0.2em 0.5em;
        text-align: center;
    }

    th {
        font-weight: normal;
    }

    thead th,
    tfoot th {
        font-weight: bold;
    }

    th:last-child,
    td:last-child {
        border-inline-end: 0;
    }

    tfoot tr {
        th,td {
            border-bottom: 0;
        }
    }

    .vehicle-col {
        width: 100px;
        text-align: end;
    }

    tbody,
    tfoot {
        .day-col,
        .hour-col {
            text-align: end;
        }
    }

    thead tr {
        background: #e3f0ed;
    }

    tfoot {
        td, th {
            font-weight: bold;
        }
    }
}