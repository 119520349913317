.profile-page {
    .profile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 900px;
        margin: 0 auto;
        align-items: flex-start;

        margin-bottom: 40px;
        padding: 0;
        border: none;

        .avatar-bio {
            flex: 1;
            order: 0;
            margin-inline-end: 30px;

            @media (max-width: 800px) {
                margin-inline-end: 20px;
            }

            @media (max-width: 700px) {
                margin-inline-end: 10px;
            }

            @media (max-width: 550px) {
                margin-inline-end: 5px;
            }

            .avatar-image {
                margin: 0;
                margin-bottom: 20px;

                .image {
                    width: 100%;
                }

                .no-image-icon {
                    height: 300px;

                    @media (max-width: 950px) {
                        height: 30vw;
                    }
                }
            }

            .bio {
                margin-bottom: 30px;
                word-break: break-word;
            }

            .no-bio {
                color: #777;
                display: flex;
                align-items: center;

                .icon {
                    margin-inline-end: 10px;
                    font-size: 20px;
                }

                .text {
                    margin: 0;
                    font-size: 12px;
                }
            }
        }

        .profile-details {
            flex: 2;
            order: 1;
            margin-inline-start: 30px;

            @media (max-width: 800px) {
                margin-inline-start: 20px;
            }

            @media (max-width: 700px) {
                margin-inline-start: 10px;
            }

            @media (max-width: 550px) {
                margin-inline-start: 5px;
            }

            .profile-title {
                font-size: clamp(24px, 4.5vw, 38px);
                margin-bottom: 5px;
                word-break: break-word;
            }

            .joined-actions {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;

                .joined {
                    margin-bottom: 0;

                    .username {
                        display: block;
                        word-break: break-word;
                    }

                    .datetime {
                        font-size: 12px;
                        color: #777;
                    }
                }

                @media (max-width: 700px) {
                    .edit-profile-link .superfluous {
                        display: none;
                    }
                }

                @media (max-width: 450px) {
                    .edit-profile-link {
                        .icon { margin: 0; }
                        .text { display: none; }
                    }
                }

                .edit-profile-actions {
                    text-align: end;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex: 1;
                }
            }

            .social-links {
                padding: 0;
                list-style-type: none;
                margin-bottom: 50px;
                word-break: break-word;

                .item {
                    margin-bottom: 20px;

                    .link {
                        text-decoration: none;

                        .icon {
                            margin-inline-end: 10px;
                            color: #000;
                            font-size: 150%;
                            vertical-align: middle;
                        }

                        .text {
                            text-decoration: underline;
                        }
                    }
                }

                .item.website .label {
                    display: inline-block;
                    margin-inline-end: 10px;
                }
            }

            .rides {
                margin-bottom: 50px;

                .rides-title {
                    font-size: 28px;
                    margin-bottom: 20px;
                }

                .no-rides {
                    margin-top: -10px;
                    color: #777;

                    .icon {
                        margin-inline-end: 5px;
                    }
                }
    
                .ride {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 30px;

                    
    
                    .ride-image {
                        flex: 1;
                        order: 0;
                        margin: 0;
                        
                        .polaroid {
                            max-width: 100%;
                            border-width: 5px;
                            border-bottom-width: 20px;
                        }

                        .no-image-icon {
                            height: 142px;
                        }
                    }
    
                    .details {
                        flex: 2;
                        order: 1;
                        margin-inline-start: 20px;

                        @media (max-width: 600px) {
                            margin-inline-start: 10px;
                        }

                        .name {
                            text-decoration: underline;
                            font-size: 18px;
                            margin-bottom: 15px;

                            @media (max-width: 750px) {
                                font-size: 16px;
                            }
                        }
                    }

                    @media (max-width: 500px) {
                        flex-direction: column;

                        .ride-image {
                            .polaroid {
                                border-width: 5px;
                            }
                        }

                        .details {
                            margin-inline-start: 0;
                            margin-top: 10px;
                        }
                    }
                }
            }

            .view-drives-link {
                font-size: 28px;
                text-decoration: none;
                color: #fe3f40;

                @media (max-width: 600px) {
                    font-size: 5vw;
                }

                .icon {
                    margin-inline-end: 10px;
                    font-size: 150%;
                    vertical-align: middle;
                }

                .text {
                    vertical-align: middle;
                }
            }
        }
    }

    .profile.edit-profile {
        position: relative;

        @media (max-width: 600px) {
            display: block;

            .profile-details {
                margin-inline-start: 0;
            }

            .avatar-bio {
                margin-inline-end: 0;
            }
        }

        .edit-profile-actions {
            .button {
                margin-inline-start: 20px;

                @media (max-width: 750px) {
                    .superfluous {
                        display: none;
                    }
                }
            }
        }

        .profile-details-form {
            margin-bottom: 30px;
        }

        .rides {
            .ride-form {
                position: relative;
                margin-bottom: 30px;

                .name-image-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    .image-box {
                        order: 1;
                        flex: 1;
                        min-width: 33.333%;

                        .polaroid {
                            max-width: 100%;
                            border-width: 5px;
                            border-bottom-width: 20px;
                        }

                        .no-image-icon {
                            height: 142px;
                        }

                        .image-options {
                            margin-top: 10px;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;

                            .button,
                            .link-button {
                                font-size: 11px;
                            }

                            .upload-image {
                                margin-inline-start: 10px;
                            }
                        }
                    }

                    .name-box {
                        order: 2;
                        flex: 2;
                        margin-inline-start: 20px;

                        @media (max-width: 600px) {
                            margin-inline-start: 10px;
                        }

                        .form-row.buttons {
                            text-align: start;

                            .button {
                                margin-inline-end: 10px;
                            }
                        }
                    }
                }
                
                .ride-description {
                    min-height: 120px;
                }
            }

            .ride-actions {
                text-align: end;
                margin-top: 10px;
                margin-inline-end: 20px;
            }
        }

        .bio-description {
            min-height: 200px;
        }

        .avatar-image .avatar-actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 10px;

            .upload-avatar {
                margin-inline-start: 10px;
            }
        }
    }
}

body.rtl {
    .profile-page .profile.edit-profile .rides .ride-form .remove-ride {
        right: auto;
        left: 20px;
    }
}