.review-detail-modal {
    max-width: 700px;

    .drive-props {
        margin-bottom: 15px;
    }

    .review-text {
        background: #fff;
        padding: 4px 6px;
        border-radius: 3px;
        margin-bottom: 20px;
        max-height: clamp(120px, 25vh, 300px);
        overflow-y: auto;
        word-break: break-word;

        p {
            margin-bottom: 0;
        }
    }

    .review-info {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .review-avatar {
            width: 65px;
            height: 65px;
            margin-inline-end: 10px;
            border-radius: 3px;
            box-shadow: 0 2px 5px rgba(0,0,0,0.5);
        }

        .review-avatar.no-image {
            padding: 5px;
            background: #eaeaea;
            border: 2px solid #fff;
        }

        .rating-author {
            .star-rating {
                margin-bottom: 10px;
            }
        
            .author {
                margin: 0;
                word-break: break-all;

                .author-link {
                    font-weight: bold;
                }
            
                .created-date {
                    margin-inline-start: 10px;
                    font-size: 12px;
                    color: #777;
                }
            }
        }
    }
}