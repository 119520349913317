.users-drive {
    background: #fff;
    border: 2px solid #fff;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
    word-break: break-word;

    .main-title {
        font-size: clamp(20px, 2vw, 24px);
        margin-bottom: 5px;
    }

    .drive-author {
        font-size: 12px;
        color: #777;
        margin-bottom: 20px;

        a { color: #444; text-decoration: none; }
    }

    .drive-meta-data {
        margin-bottom: 20px;
    }

    .review {
        margin-bottom: 20px;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 5px;
            margin-bottom: 10px;
            border-bottom: 1px solid #ccc;

            .icon-container {
                display: inline-block;
                width: 25px;

                .icon {
                    color: #ff403f;
                }
            }

            .title {
                margin: 0;
            }

            .update-review-button {
                color: #ff403f;
            }
        }

        .review-info {
            margin-left: 25px;

            .review-text {
                margin-bottom: 10px;

                > p {
                    margin-bottom: 0;
                }
            }
        }
    }

    .footer-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
}

.users-drive.selected {
    border-color: #ff7600;
}

.users-drives-list {
    .users-drive {
        margin-bottom: 20px;
    }

    .users-drive:last-child {
        margin-bottom: 0;
    }
}

.my-drives-section {
    .my-drives-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 20px;

        .avatar {
            order: 1;
            flex: 0;
            min-width: 100px;

            .image {
                max-width: 100%;
            }
        }

        .info {
            order: 2;
            flex: 1;
            margin-inline-start: 20px;
            word-break: break-word;

            .title {
                font-size: clamp(24px, 4.5vw, 32px);
                margin-bottom: 5px;
            }

            .username {
                font-weight: bold;
                margin-bottom: 0;
            }

            .joined {
                font-size: 12px;
                color: #777;
            }
        }

        
    }
}