.sitemap-drives {
    max-width: 600px;
    margin: 0 auto;
    background: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);

    .title {
        .icon {
            margin-inline-end: 10px;
        }
    }

    .paging-controls {
        margin: 20px -20px;
        padding: 3px 20px;
        background: #fff;
    }

    .country-item .title {
        font-size: 16px;
    }

    .drives-list {
        margin-bottom: 40px;

        .drive-item {
            margin-bottom: 5px;
        }
    }

    .loading-detail-page {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

.paging-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 2px 0;

    .record-count {
        margin: 0;
        font-weight: bold;
    }

    .paging {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .page-item .page-link {
            text-decoration: none;
            display: block;
            text-align: center;
            width: 2em;
            line-height: 2em;
            font-weight: bold;
            background: #bd8837;
            border: 1px solid #bd8837;
            color: #fff;
            margin-inline-start: 5px;
        }

        .page-item.current .page-link {
            background: #fff;
            color: #bd8837;
        }
    }
}