.confirm-drive-page {
    .form {
        margin-bottom: 30px;
    }

    .review-row {
        textarea {
            min-height: 80px;
        }
    }

    .drive-meta-data {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .form-row.buttons {
        margin-top: 30px;
        margin-bottom: 20px;

        .confirm-button {
            font-size: 19px;
            padding-inline-start: 20px;
            padding-inline-end: 20px;

            .icon {
                margin-inline-end: 10px;
            }
        }
    }

    .video-row {
        margin-top: 30px;
    }
}