.modal-background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background: rgba(0,0,0,0.8);
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

.standard-modal {
    background: #ebebeb;
    width: 100%;
    max-width: 500px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.9);
    margin: 0 10px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        padding-top: 15px;
        padding-bottom: 15px;

        background: #fcbe64;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        @media (max-width: 450px) {
            padding: 10px;
        }

        .title {
            margin: 0;
            color: #a06000;
            text-shadow: 0 -0.5px #3c2400, 0 0.5px #ffd799;
            margin-inline-end: 15px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .text {
                margin-inline-start: 15px;
                font-size: clamp(12px, 2.8vw, 20px);
                word-break: break-word;
            }
        }

        .close {
            flex: 0;
        }

        .icon {
            font-size: 24px;
        }
    }

    .main {
        padding: 20px;
        overflow-y: auto;

        @media (max-width: 450px) {
            padding-left: 10px;
            padding-right: 10px;
        }

        .form-row {
            margin-bottom: 20px;
        }

        .form-row.star-row {
            margin-bottom: 40px;
        }

        .form-row.buttons {
            margin-bottom: 0;
            text-align: left;
            display: flex;
            justify-content: flex-start;

            @media (max-width: 450px) {
                .button,
                .link-button {
                    font-size: 12px;
                    margin-inline-end: 10px;
                }

                .superfluous {
                    display: none;
                }
            }

            .primary-button {
                margin-inline-start: 0;
            }

            .flex-gap {
                flex: 1;
            }

            .delete-button {
                float: inline-end;
                margin-inline-end: 0;
                background: red;
            }
        }

        .legend-form {
            padding: 10px;
            margin: 0;
            margin-bottom: 20px;
            border: 1px solid #ccc;

            .form-row {
                margin-bottom: 6px;
            }

            .form-row:last-child {
                margin-bottom: 0;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        border-top: 1px solid #ddd;

        .button {
            margin-inline-end: 20px;
        }

        .button:last-of-type {
            margin-inline-end: 0;
        }

        @media (max-width: 450px) {
            .button,
            .link-button {
                font-size: 12px;
                margin-inline-end: 10px;
            }

            .superfluous {
                display: none;
            }
        }

        .primary-button {
            margin-inline-start: 0;
        }

        .flex-gap {
            flex: 1;
        }

        .delete-button {
            float: inline-end;
            margin-inline-end: 0;
            background: red;
        }
    }

    .media-form-items {
        .media-item {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            padding-bottom: 10px;
            margin-bottom: 8px;
            border-bottom: 1px solid #c7c7c7;

            @media (max-width: 450px) {
                .video-media {
                    width: 50%;

                    .video-item {
                        width: 100%;
                    }
                }
            }
    
            .details {
                flex: 1;
                padding-inline-start: 20px;
                font-size: 13px;
    
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                @media (max-width: 450px) {
                    padding-inline-start: 10px;
                }
    
                .title {
                    font-size: 13px;;
                }
    
                .data-list {
                    flex: 1;
                    font-size: 12px;
    
                    .item {
                        margin-bottom: 2px;
                    }
                }
    
                .delete-button {
                    font-size: 13px;
                }
    
                .form-row {
                    width: 100%;
                }

                @media (max-width: 450px) {
                    .textbox,
                    textarea,
                    .button,
                    .delete-button {
                        font-size: 12px;
                    }

                    textarea {
                        padding: 5px;
                    }
                }
            }
        }
    }

    .icon-floated {
        float: left;
        margin-right: 10px;
        margin-bottom: 5px;
        font-size: 40px;
    }

    .edit-links-list {
        .link-item {
            padding: 10px;
            margin: 0;
            margin-bottom: 10px;
            border: none;
            border-radius: 6px;
            background: #f5f5f5;
            box-shadow: 0 2px 5px rgba(0,0,0,0.2);
            padding-top: 5px;

            .legend {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .title {
                    font-size: 12px;
                    margin: 0;
                }

                .remove-button {
                    background: #ff0000;
                    font-size: 13px;
                    min-height: 0;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    min-width: 6em;
                }
            }

            .controls {
                .row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 12px;

                    .label {
                        flex: 0;
                        min-width: 3em;
                        margin-inline-end: 5px;
                        text-align: end;
                        font-weight: bold;
                    }

                    .textbox {
                        flex: 1;
                        font-size: 12px;
                        height: 25px;
                    }

                    .read-only-value {
                        margin: 0;
                        flex: 1;
                        font-size: 12px;
                        line-height: 25px;

                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 1px;
                        white-space: nowrap;
                        margin-inline-start: 10px;
                    }
                }

                .row.label-row {
                    margin-bottom: 2px;
                }

                .validation-error {
                    margin-inline-start: 3em;
                }
            }
        }

        .link-item.read-only-item {
            .controls {
                .row .read-only-value {
                    line-height: 22px;
                }

                .row.label-row {
                    margin-bottom: 0;
                }
            }
        }

        .actions {
            margin-top: 20px;
        }
    }
}

.rtl .standard-modal .icon-floated {
    float: right;
    margin-right: 0;
    margin-left: 10px;
}